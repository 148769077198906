@import "src/styles/variables/layout";
@import "src/styles/variables/colors";

.container {
    flex: 1 1 auto;
    display: flex;
}

.content {
    display: flex;
    flex: 1 1 auto;

    flex-direction: row;

    padding: spacing(5, $header-size) spacing(5) spacing(5) spacing(5);

    backdrop-filter: blur(1px);
    background-color: $base-overlay-color;


    svg {
        margin: auto;
        width: 100%;
        max-height: calc(var(--app-height) - #{$header-size} - 80px);
    }
}
