@import "src/styles/variables/layout";
@import "src/styles/variables/colors";

.container {
    flex: 1 1 auto;
    display: flex;

    background: url("../../../../assets/visuals/map.jpg") no-repeat center center fixed;

    background-size: cover;
}

.content {
    display: flex;
    flex: 1 1 auto;

    flex-direction: row;

    padding: spacing(5, $header-size) spacing(5) spacing(5) spacing(5);

    backdrop-filter: blur(1px);
    background-color: $base-overlay-color;
}

.explorer {
    display: flex;

    flex: 1 1 auto;

    flex-direction: column;

    border-radius: 8px;

    backdrop-filter: blur(1px);
    box-shadow: 0 2px 4px 0 $base-shadow-color;
    background-color: $base-background-color;

    margin: 0 0 0 spacing(2, -1);

    padding: spacing(3, 1);

    .header {
        display: flex;
        flex-direction: row;

        margin-bottom: spacing(4, -2);

        span {
            font-family: Futura;
            font-size: 18px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: $blue-dark-1;
        }

        button {
            margin: 0 0 0 auto;
        }
    }

    .info {
        margin-bottom: spacing(2, -1);

        display: flex;
        flex-direction: row;

        div {
            display: flex;
            flex-direction: row;

            align-items: center;

            &:not(:last-child) {
                margin-right: spacing(4, -2);
            }

            > span:first-child {
                display: flex;
                border: solid 1px;
                border-radius: 50%;
                width: 40px;
                height: 40px;

                margin-right: spacing(2, -1);

                i {
                    margin: auto;
                }
            }

            >span:nth-child(2) {
                display: flex;
                flex-direction: column;

                >span:first-child {
                    font-family: Futura;
                    font-size: 18px;
                    font-weight: 500;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: normal;
                    letter-spacing: normal;
                    color: $blue-dark;
                }

                >span:nth-child(2) {
                    font-size: 12px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: normal;
                    letter-spacing: normal;
                    color: $grey-light;
                }
            }
        }
    }
}

.loader {
    margin: auto;
    color: $base-loader-color;
}
