@import 'src/styles/variables/layout';
@import 'src/styles/variables/colors';

.container {
    font-weight: bold;
    font-size: px-to-em(22);
    color: $base-text-color;
}

.size18 {
    font-size: px-to-em(18);
}

.size14 {
    font-size: px-to-em(14);
}

.size24 {
    font-size: px-to-em(24);
}

.size44 {
    font-size: px-to-em(44);
}
