@import 'src/styles/variables/layout';
@import 'src/styles/variables/colors';
@import 'src/styles/variables/animations';


@mixin hoverStyles($color-hover, $color-pressed) {
    &:hover {
        color: $color-hover;

        text-decoration: underline;
    }

    &:active,
    &:focus {
        color: $color-pressed;
    }
}

.wrapper {
    display: flex;
    flex-direction: row;
    position: relative;
    display: inline-flex;
    font-weight: normal;
    text-align: center;
    justify-content: center;
    touch-action: manipulation;
    cursor: pointer;
    background-image: none;
    white-space: nowrap;
    user-select: none;
    transition: background-color 225ms cubic-bezier(0.645, 0.045, 0.355, 1);
    background-color: transparent;
    border: 0px;
    box-shadow: none;
    text-decoration: none;
    outline: none;
    padding: 0;
}

.text {
    display: flex;
    font-size: px-to-em(16);
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: right;
    align-items: center;

    color: $base-button-color;

    @include hoverStyles($base-button-color, $base-button-color);

    &.isSecondary {
        color: $secondary-button-color;

        @include hoverStyles($secondary-button-color, $secondary-button-color);
    }

    
}

.icon {
    margin-right: spacing(1, -2);
}

.isDisabled {
    cursor: not-allowed;
}
