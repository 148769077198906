@import 'src/styles/variables/colors';
@import 'src/styles/variables/layout';

.authWrapper {
    position: fixed;

    display: flex;
    flex: 1 1 auto;

    align-items: center;

    width: 100%;
    background: $white;

    color: $blue-light;

    height: spacing(0, $header-size);

    top: 0px;

    z-index:950;

    backdrop-filter: blur(1px);

    box-shadow: 0 2px 4px 0 rgba(11, 108, 115, 0.25);
}


.logo {
    margin: auto auto auto spacing(5);
}