/* ------------------------------------- */

$white: rgba(255, 255, 255, 0.85);
$blue-light: #5cd5e5;
$blue-light-1: rgba(214, 245, 249, 0.8);
$blue-dark: #0b6c73;
$blue-dark-1: #0b6b73;
$blue-dark-2: rgba(11, 108, 115, 0.25);
$grey-light: #d7d9e3;
$yellow: #f4de77;
$coral: #ef7b75;


$base-background-color: $white;
$base-text-color: $blue-dark;
$secondary-text-color: $blue-light;
$base-border-color: $grey-light;
$base-focus-color: $blue-light;
$base-placeholder-color: $grey-light;
$base-button-color: $blue-light;
$secondary-button-color: $white;
$base-loader-color: $blue-light;
$base-overlay-color: $blue-light-1;
$base-shadow-color: $blue-dark-2;
