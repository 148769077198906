@import "src/styles/variables/layout";
@import "src/styles/variables/colors";

.panel {
    margin: 0 0 0 auto;

    display: flex;
    flex-direction: column;
}

.button {
    &.autoTop {
        margin-top: auto;
    }

    &.largeBottom {
        margin-bottom: spacing(4, -2);
    }

    &.smallBottom {
        margin-bottom: spacing(1, 2);
    }


    span {
        padding: spacing(2, -2) spacing(2, -3);
        border-radius: 8px;
        backdrop-filter: blur(1px);
        box-shadow: 0 2px 4px 0 $base-shadow-color;
        background-color: $base-background-color;

        i {
            margin: 0;
        }
    }
}
