@import "src/styles/variables/layout";
@import "src/styles/variables/colors";

.inputWrapper {
    border: 0;
    background-color: transparent;
    
    font-size: px-to-em(16);
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;

    color: $base-text-color;

    padding: 0;
    padding: spacing(1, -4px) 0;
    width: 100%;

    margin-bottom: spacing(3, -4px);

    border-bottom: 1px solid $base-border-color;

    &::placeholder {
        color: $base-placeholder-color;
    }

    &:focus {
        border-bottom: 1px solid $base-focus-color;
        outline: none;
    }
}