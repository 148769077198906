@import "src/styles/variables/layout";
@import "src/styles/variables/colors";

.container {
    background-color: $base-background-color;
}

.wrapper {
    position: relative;
    background-color: $base-background-color;
    display: flex;
    flex-direction: column;

    min-height: var(--app-height);
}

.loader {
    margin: auto;
    color: $base-loader-color;
}