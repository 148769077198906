@import 'src/styles/variables/layout';
@import 'src/styles/variables/colors';
@import "src/styles/variables/animations";

@mixin hoverStyles($color-hover, $color-pressed, $color-hover-text: null, $color-pressed-text: null) {
    &:hover {
        background-color: $color-hover;

        @if $color-hover-text {
            color: $color-hover-text;
        }
    }

    &:active,
    &:focus {
        background-color: $color-pressed;

        @if $color-hover-text {
            color: $color-hover-text;
        }

        @if $color-pressed-text {
            color: $color-pressed-text;
        }
    }
}

.wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    transition: $animation-background;
    border-radius: 4px;
    padding: spacing(1, 6) spacing(1, 4) spacing(1, 5);
    transition: background-color border-color $animation-hover;
    outline: none;
    text-decoration: none;
    border: 0;
    width: 100%;
    box-sizing: border-box;

    i {
        display: inline-block;
        margin-right: spacing(1);
    }

    span + i {
        margin-right: 0;
        margin-left: spacing(1);
    }

    color: $base-text-color;

    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;

    background-color: $base-button-color;
}

.hasIcon {
    padding: spacing(0, 6) spacing(0, 7);

    i {
        margin-right: 0;
        margin-bottom: -4px;
    }
}

.isDisabled {
    cursor: not-allowed;
}
