@import "src/styles/variables/layout";
@import "src/styles/variables/colors";

.overview {
    margin: 0 auto auto 0;
    display: flex;
    flex-direction: column;

    width: 380px;
    height: calc(var(--app-height) - 280px);

    border-radius: 8px;
    backdrop-filter: blur(1px);
    box-shadow: 0 2px 4px 0 rgba(11, 108, 115, 0.25);
    background-color: $base-background-color;

    overflow-y: overlay;
}

.nav {
    display: flex;
    flex-direction: row;
    margin: spacing(4, -2) spacing(4, -2) 0;
    padding: spacing(1, 0) spacing(2, -1);

    opacity: 0.7;
    border-radius: 8px;
    background: linear-gradient(to left, #0b6b73 0%, #5cd5e5 47%, #d6f5f9 100%);

    span {
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
    }

    span:not(:first-child) {
        margin-left: auto;
    }

    span:first-child {
        color: $blue-dark;
    }

    span:last-child {
        color: $white;
    }
}

.total {
    display: flex;
    flex-direction: row;
    padding: spacing(3, -1) spacing(4, -2) 0;

    align-items: baseline;

    span {
        &:first-child {
            font-family: Futura;
            font-size: 36px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: $blue-dark-1;

            padding-right: spacing(0, 5);
        }

        font-size: 18px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: $blue-dark-1;
    }
}

.area {
    display: flex;
    flex-direction: column;

    border-top: 1px solid $base-border-color;

    &:nth-child(3) {
        border-top: 0;
        cursor: auto;
    }

    &:last-child {
        border-bottom: 1px solid $base-border-color;
    }

    padding: spacing(2, -1) spacing(4, -2);

    div {
        display: flex;
        flex-direction: row;

        span {
            display: flex;
            flex: 1 1 auto;
            font-size: 18px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: $blue-dark;

            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            span {
                text-align: start;

                border-top: 3px solid;

                font-size: 18px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;

                &.isSufficient {
                    border-color: $blue-light;
                    color: $blue-light;
                }

                &.isModerate {
                    border-color: $yellow;
                    color: $yellow;
                }

                &.isInSufficient {
                    border-color: $coral;
                    color: $coral;
                }
            }
        }

        .circle {
            margin-left: auto;
            border-radius: 50%;
            background-color: #0b6b73;

            flex: none;

            width: 20px;
            height: 20px;

            background: linear-gradient(to bottom, #95ecf4, #5cd5e5);

            &.dark {
                background: linear-gradient(to bottom, #1aa5ab, #0b6c73);
            }
        }
    }
}
