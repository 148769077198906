@import 'src/styles/variables/layout';
@import 'src/styles/variables/colors';

.container {
    height: 100%;
    position: relative;
    background-color: $base-background-color;
    overflow-x: hidden;
}

.topCloud {
    position: absolute;
    top: 0px;
    right: -80px;
    width: auto;
    height: 165px;

    @include media-query(mobile) {
        top: -30px;
    }
}

.bottomCloud {
    position: absolute;
    left: 80px;
    bottom: 0;
    width: 595px;
    height: 204px;

    @include media-query(mobile) {
        left: -125px;
    }
}

.content {
    padding: spacing(3);
    z-index: 10;
    position: relative;
    height: 100%;
}

.title {
    margin-top: spacing(18, 4px);
    text-align: center;
    line-height: 1.23;

    @include media-query(mobile) {
        margin-top: spacing(7);
    }
}

.buttonWrapper {
    margin: spacing(7, -2px) auto 0;
    max-width: 327px;

    @include media-query(mobile) {
        margin-top: spacing(3, 3px);
    }
}

.logo {
    margin: 0 auto;
}

.showFooter {
    .content {
        height: 100vh;
        height: var(--app-height);
    }
}
