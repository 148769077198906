@import "src/styles/variables/layout";
@import "src/styles/variables/colors";

.overview {
    margin: 0 auto auto 0;
    display: flex;
    flex-direction: column;

    width: 380px;
    height: calc(var(--app-height) - 280px);

    border-radius: 8px;
    backdrop-filter: blur(1px);
    box-shadow: 0 2px 4px 0 rgba(11, 108, 115, 0.25);
    background-color: $base-background-color;

    overflow-y: overlay;
}

.nav {
    display: flex;
    flex-direction: row;
    padding: spacing(4, -2) spacing(4, -2) 0;
}

.navItem {
    flex: 1 1 auto;
    padding: spacing(0, 4) spacing(1, 2);
    border: solid 1px $base-border-color;
    border-right: none;

    &.isAll {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
        span {
            color: $blue-dark;
        }
    }

    &.isSufficient {
        span {
            color: $blue-light;
        }
    }

    &.isModerate {
        span {
            color: $yellow;
        }
    }

    &.isInSufficient {
        border: solid 1px $base-border-color;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;

        span {
            color: $coral;
        }
    }

    &.isSelected {
        border-color: $blue-dark;
        background-color: $blue-dark;
        span {
            color: $white;
        }
    }

    span {
        font-size: 18px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;

        &:hover {
            text-decoration: none;
        }
    }
}

.total {
    display: flex;
    flex-direction: row;
    padding: spacing(3, -1) spacing(4, -2) 0;

    align-items: baseline;

    span {
        &:first-child {
            font-family: Futura;
            font-size: 36px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: $blue-dark-1;

            padding-right: spacing(0, 5);
        }

        font-size: 18px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: $blue-dark-1;
    }
}

.area {
    display: flex;
    flex-direction: column;

    border-top: 1px solid $base-border-color;

    &:nth-child(3) {
        border-top: 0;
        cursor: auto;
    }

    &:last-child {
        border-bottom: 1px solid $base-border-color;
    }

    padding: spacing(2, -1) spacing(4, -2);

    cursor: pointer;

    > div:first-child {
        display: flex;
        flex-direction: row;

        > span:first-child {
            display: flex;
            flex: none;
            width: 61%;
            font-size: 18px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: $blue-dark;

            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            padding-right: spacing(5);

            > span {
                text-align: start;

                border-top: 3px solid;

                font-size: 18px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;

                &.isSufficient {
                    border-color: $blue-light;
                    color: $blue-light;
                }

                &.isModerate {
                    border-color: $yellow;
                    color: $yellow;
                }

                &.isInSufficient {
                    border-color: $coral;
                    color: $coral;
                }
            }
        }

        > i {
            display: flex;
            flex: 1 1 auto;
            justify-content: center;
            width: 13%;

            @media not all and (min-resolution: 0.001dpcm) {
                display: -webkit-box;
            }
        }

        > span {
            flex: 1 1 auto;

            width: 13%;

            font-size: 18px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: center;
            color: $blue-light;

            &.isSufficient {
                color: $blue-light;
            }

            &.isModerate {
                color: $yellow;
            }

            &.isInSufficient {
                color: $coral;
            }
        }
    }

    > div:nth-child(2) {
        margin-top: spacing(1, 2);

        display: none;

        &.show {
            display: flex;
        }

        flex-wrap: wrap;

        > div {
            padding: spacing(1, 0) spacing(1, 4);
            border-radius: 8px;
            border: solid 1px $base-border-color;

            display: flex;
            flex-direction: row;

            margin-top: spacing(1, 2);

            &:not(:last-child) {
                margin-right: spacing(1, 0);
            }

            > span:first-child {
                font-size: 12px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                color: $blue-dark;
            }

            > span:not(:first-child) {
                font-size: 12px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;

                color: $blue-dark;

                > i {
                    margin: 0 spacing(0, 5) 0 spacing(1, 2);
                }

                display: flex;
                flex-direction: row;

                &.isSufficient {
                    color: $blue-light;
                }

                &.isModerate {
                    color: $yellow;
                }

                &.isInSufficient {
                    color: $coral;
                }
            }
            
            &.isSelected {
                border-color: $blue-dark;
                background-color: $blue-dark;
            
                span {
                    color: $white;
                }
            }
        }
    }
}
