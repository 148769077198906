@import "src/styles/variables/layout";
@import "src/styles/variables/colors";

.container {
    flex: 1 1 auto;
    display: flex;

    background: url("../../../../assets/visuals/planner.jpg") no-repeat center center fixed;

    background-size: cover;
}

.content {
    display: flex;
    flex: 1 1 auto;

    flex-direction: row;

    padding: spacing(5, $header-size) spacing(5) spacing(5) spacing(5);
}

.box {
    width: 380px;
    height: 488px;

    box-shadow: 0 2px 4px 0 $base-shadow-color;

    img {
        border-radius: 8px;
    }
}
