$font-location: "../assets/fonts";
$font-display: "swap";

@font-face {
  font-family: "Futura";
  src: local("Futura-Medium"), url($font-location + "/Futura/Futura-Medium.woff2") format("woff2"),
    url($font-location + "/Futura/Futura-Medium.woff2") format("woff"),
    url($font-location + "/Futura/Futura-Medium.woff2") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  font-display: $font-display;
}

@font-face {
  font-family: "Lato";
  src: local("Lato-Regular"), url($font-location + "/Lato/Lato-Regular.woff2") format("woff2"),
    url($font-location + "/Lato/Lato-Regular.woff2") format("woff"),
    url($font-location + "/Lato/Lato-Regular.woff2") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  font-display: $font-display;
}
