@import "src/styles/variables/layout";
@import "src/styles/variables/colors";

.nav {
    display: flex;
    flex-direction: row;

    margin-bottom: spacing(2, -1);

    > div {
        &:first-child {
            margin-left: auto;
        }

        &:not(:last-child) {
            margin-right: spacing(4, -2);
        }

        > span:first-child {
            border-radius: 50%;
            width: 9px;
            height: 9px;
            display: inline-block;

            margin-right: spacing(0, 3);
        }

        > span {
            font-size: 12px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
        }
    }
}
