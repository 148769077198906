@import "src/styles/variables/colors";
@import "src/styles/variables/layout";

.menuWrapper {
    margin: auto spacing(5) auto 0;
}

.dropdownContent {
    &.isVisiable {
        display: block;
    }

    position: absolute;

    color: $white;
    display: none;

    background-color: $white;

    z-index: 1;

    top: $header-size;
    right: spacing(5);

    span {
        display: block;
        text-decoration: none;
        font-size: 18px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: $secondary-text-color;

        &:hover {
            cursor: pointer;
            text-decoration: underline;
        }

        padding: spacing(2);
    }
}

.bell {
    margin: auto spacing(5) auto auto;
}

.nav {
    margin: auto;
    display: flex;
}

.navItem {
    padding: 0 spacing(4, -2);

    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;

    display: block;

    height: $header-size;
    line-height: $header-size;

    cursor: pointer;

    text-decoration: none;

    color: $secondary-text-color;

    &.isActive {
        border-bottom: 2px solid $base-focus-color;
        color: $base-text-color;
    }
}
