@import "src/styles/variables/layout";
@import "src/styles/variables/colors";

.container {
    flex: 1 1 auto;
    display: flex;

    background: url("../../../../assets/visuals/heat.jpg") no-repeat center center fixed;

    background-size: cover;
}

.content {
    display: flex;
    flex: 1 1 auto;

    flex-direction: row;

    padding: spacing(5, $header-size) spacing(5) spacing(5) spacing(5);
}